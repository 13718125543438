<template>
  <div>
    <div class="row">
      <div class="col-3 col-mobile-profile-edit pt-2">
        <span class="user-avatar" @click="openUserAvatarDialog">
          <img
            id="avatar-image"
            :alt="formData.name"
            :src="formData.avatar.url"
            class="rounded-circle card-profile-image__client"
          />
          <span
            v-if="!formData.avatar.url"
            class="material-icons add-photo profile-icon-profile"
          >
            add_a_photo</span
          >
        </span>
      </div>
      <div class="col-9 col-mobile-profile-edit pt-2">
        <div class="form">
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
              <base-input
                v-model="formData.name"
                :value="formData.name"
                label="Nombre completo"
                placeholder="Nombre completo"
                type="text"
              ></base-input>
              <label class="form-control-label">Teléfono</label>
              <vue-tel-input
                v-model="formData.phone"
                :input-options="inputOptions"
                mode="international"
                class="tel-admin-input"
                :auto-default-country="true"
              ></vue-tel-input>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
              <div class="form-group multiselect-form-country">
                <label class="form-control-label">Zona horaria</label>
                <multiselect
                  v-model="formData.timezone"
                  :close-on-select="true"
                  :options="timezones"
                  :searchable="true"
                  :show-labels="false"
                  class="select-danger details-country"
                  placeholder="Selecciona zona horaria"
                ></multiselect>
              </div>

              <div class="form-group multiselect-form-country">
                <label class="form-control-label">Pais</label>
                <multiselect
                  v-model="formData.country"
                  :close-on-select="true"
                  :options="getAllCountries"
                  :searchable="true"
                  :show-labels="false"
                  class="select-danger details-country"
                  label="name"
                  placeholder="Selecciona un pais"
                  track-by="id"
                >
                  <template #option="props">
                    <div class="option__desc">
                      <span class="option__title">{{
                        `(${props.option.code}) ${props.option.name}`
                      }}</span>
                      <span
                        v-if="
                          props.values &&
                          props.values.length &&
                          !props.values.isOpen
                        "
                        class="multiselect__single"
                        >{{ props.values.length }} options selected</span
                      >
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>
            <base-button
              :loading="isLoadingUpdateUser"
              class="ml-3 btn btn-maquillate py-2 px-3"
              @click="updateUser"
            >
              Actualizar datos
            </base-button>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-4" />
    <validation-observer ref="formValidator">
      <div class="form needs-validation">
        <h6 class="heading-small text-color-title mb-3">Correo electrónico</h6>
        <div>
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
              <base-input
                v-model="formData.email"
                :disabled="!isChangeEmail"
                :placeholder="formData.email"
              ></base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 align-button-center">
              <base-button
                v-if="!isChangeEmail"
                type="primary btn-maquillate py-2 px-3"
                @click="changeEmail"
              >
                Cambiar correo electrónico
              </base-button>

              <div v-else>
                <base-button
                  :loading="isLoadingEmail"
                  type="primary btn-maquillate py-2 px-3"
                  @click="resetEmail()"
                >
                  Aplicar cambio
                </base-button>
                <base-button
                  class="cancelar btn-maquillate-cancel py-2 px-3"
                  type="default"
                  @click="changeEmail"
                >
                  Cancelar
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </validation-observer>
    <hr class="my-4" />
    <h6 class="heading-small text-color-title mb-3">Contraseña</h6>
    <div>
      <div
        v-if="!isChangePassword"
        id="password-update-container"
        class="row mb-4"
      >
        <div class="col-sm-12 col-md-6 col-lg-6">
          <base-input
            v-model="formData.password"
            alternative
            class="mb-3"
            disabled
            name="Password"
            placeholder="Contraseña actual"
            type="password"
          ></base-input>

          <base-button
            type="primary btn-maquillate py-2 px-3"
            @click="openChangePassword"
            >Cambiar contraseña
          </base-button>
        </div>
      </div>

      <div v-else id="password-update-form" class="row">
        <div class="col-sm-12 col-md-6 col-lg-6">
          <base-input
            v-model="formData.currentPassword"
            class="mb-3 control has-icons-left"
            name="Password"
            placeholder="Contraseña actual"
            type="password"
          ></base-input>

          <base-input
            id="user.password"
            v-model="formData.password"
            class="mb-3 control has-icons-left"
            placeholder="Nueva contraseña"
            type="password"
          />

          <base-input
            v-model="formData.repeatPassword"
            class="mb-3 control has-icons-left"
            name="Password"
            placeholder="Confirmar contraseña"
            type="password"
          ></base-input>

          <base-button
            type="primary btn-maquillate py-2 px-3"
            :loading="isLoadingPassword"
            @click="updatePassword"
            >Cambiar contraseña
          </base-button>

          <base-button
            class="cancelar btn-maquillate-cancel py-2 px-3"
            type="default"
            @click="closeChangePassword"
            >Cancelar
          </base-button>
        </div>
      </div>
    </div>
    <update-user-avatar-dialog
      :data="selectedUserAvatar"
      :is-open="isOpenUserAvatarDialog"
      @success="fetchUser"
      @close-modal="closeUserAvatarDialog"
    />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import BaseButton from '../../../../../components/BaseButton';
import UpdateUserAvatarDialog from '../../../User/components/settings/UpdateUserAvatarDialog';
import moment from 'moment-timezone';
import { mapGetters } from 'vuex';
import {
  UPDATE_USER,
  UPDATE_USER_EMAIL,
  UPDATE_USER_PASSWORD,
  USER_REQUEST,
} from '../../../../../store/actions/user';
import validator from 'validator';
import { VueTelInput } from 'vue-tel-input/dist/vue-tel-input.common';
import 'vue-tel-input/dist/vue-tel-input.css';

export default {
  name: 'SettingsAccount',
  components: {
    Multiselect,
    BaseButton,
    UpdateUserAvatarDialog,
    VueTelInput,
  },
  data() {
    return {
      isOpenUserAvatarDialog: false,
      formData: {
        id: '',
        avatar: {
          url: '',
        },
        name: '',
        phone: '',
        timezone: '',
        country: '',
        email: '',
        password: '',
        repeatPassword: '',
        currentPassword: '',
      },
      selectedUserAvatar: undefined,
      timezones: moment.tz.names(),
      isLoadingUpdateUser: false,
      isChangeEmail: false,
      isChangePassword: false,
      isLoadingEmail: false,
      isLoadingPassword: false,
      model: {
        rules: [
          {
            message: 'Un caracter en minuscula es requerido.',
            regex: /[a-z]+/,
          },
          {
            message: 'Un caracter en mayuscula es requerido.',
            regex: /[A-Z]+/,
          },
          {
            message: 'Un caracter numerico es requerido.',
            regex: /[0-9]+/,
          },
          {
            message: '8 caracteres como minimo.',
            regex: /.{8,}/,
          },
        ],
        validPassword: {
          strong: 0,
          message: '',
          alert: '',
        },
      },
      inputOptions: {
        placeholder: 'Teléfono (Opcional)',
      },
    };
  },
  computed: {
    ...mapGetters(['getAllCountries', 'getProfile']),
  },
  watch: {
    getProfile: function (newValue) {
      if (newValue) {
        this.formData = { ...newValue };
      }
    },
  },
  mounted() {
    this.formData = {
      ...this.getProfile,
    };
  },
  methods: {
    notify(value, message) {
      this.$notify({
        type: value,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    validateSettingAccountData() {
      const errors = [];

      if (!(this.formData.country && this.formData.country.id))
        errors.push('El pais es requerido');

      if (!this.formData.name) errors.push('El nombre es requerido');

      if (
        this.formData.phone &&
        !validator.matches(this.formData.phone, /^(\+)?[0-9\s]+$/)
      ) {
        errors.push('El formato del teléfono es incorrecto');
      }

      return errors;
    },
    fetchUser() {
      this.$store.dispatch(USER_REQUEST);
    },
    openUserAvatarDialog() {
      this.selectedUserAvatar = this.formData.avatar;
      this.isOpenUserAvatarDialog = true;
    },
    closeUserAvatarDialog() {
      this.selectedUserAvatar = undefined;
      this.isOpenUserAvatarDialog = false;
    },
    validateFields() {
      return !(
        this.model.validPassword.strong > 2 &&
        this.formData.password === this.formData.repeatPassword &&
        this.formData.password.length > 7 &&
        this.formData.currentPassword.length
      );
    },
    updateUser() {
      const data = {
        id: this.formData.id ? this.formData.id : '',
        name: this.formData.name ? this.formData.name : '',
        phone: this.formData.phone ? this.formData.phone : '',
        timezone: this.formData.timezone ? this.formData.timezone : '',
        country_id:
          this.formData.country && this.formData.country.id
            ? this.formData.country.id
            : '',
      };

      const validations = this.validateSettingAccountData();

      if (validations.length) {
        this.notify('danger', validations.join('</br>'));
        return;
      }

      this.isLoadingUpdateUser = true;
      this.$store
        .dispatch(UPDATE_USER, data)
        .then(() => {
          this.isLoadingUpdateUser = false;
          this.fetchUser();
          this.notify('success', 'Se ha actualizado correctamente su perfil');
        })
        .catch(() => {
          this.isLoadingUpdateUser = false;
          this.notify('danger', 'Tenemos problemas para actualizar el perfil');
        });
    },
    changeEmail() {
      this.isChangeEmail = !this.isChangeEmail;
    },
    ErrorValidationChangeEmail(data) {
      const errors = [];

      if (!validator.default.isEmail(data.email)) {
        errors.push(
          'El formato del correo esta incorrecto o el correo está vacío',
        );
      }

      return errors;
    },
    resetEmail() {
      const data = {
        email: this.formData.email ? this.formData.email : '',
      };
      let errors = this.ErrorValidationChangeEmail(data);

      if (errors.length) {
        this.notify('danger', errors.join('</br>'));
        return;
      }

      this.isLoadingEmail = true;
      this.$store
        .dispatch(UPDATE_USER_EMAIL, {
          email: this.formData.email,
        })
        .then(() => {
          this.isLoadingEmail = false;
          this.$router.push('/confirmar-correo');
        })
        .catch((error) => {
          this.isLoadingEmail = false;
          this.notify('danger', error.response.data.message);
        });
    },
    ///PASSWORD
    openChangePassword() {
      this.isChangePassword = true;
    },
    closeChangePassword() {
      this.isChangePassword = false;
      this.resetPassword();
    },

    resetPassword() {
      this.formData = {
        ...this.formData,
        currentPassword: '',
        repeatPassword: '',
        password: '',
      };
    },
    passwordValidation() {
      let errors = [];

      for (let condition of this.model.rules) {
        if (!condition.regex.test(this.formData.password)) {
          errors.push(condition.message);
        }
      }
      if (this.formData.password !== this.formData.repeatPassword) {
        errors.push('Las contraseñas no coinciden');
      }
      return errors;
    },
    updatePassword() {
      const isValidPassword = this.passwordValidation();

      if (isValidPassword.length) {
        this.notify('danger', isValidPassword.join('</br>'));
      }
      const data = {
        current_password: this.formData.currentPassword,
        password: this.formData.password,
        repeat_password: this.formData.repeatPassword,
      };

      this.isLoadingPassword = true;
      this.$store
        .dispatch(UPDATE_USER_PASSWORD, data)
        .then(() => {
          this.isLoadingPassword = false;
          this.notify('success', 'Contraseña actualizada con éxito.');
          this.closeChangePassword();
        })
        .catch((e) => {
          this.isLoadingPassword = false;
          this.notify(
            'danger',
            `${
              e.message
                ? e.message
                : 'Se ha producido un error tratando de actualizar los datos'
            }`,
          );
        });
    },
  },
};
</script>

<style>
.details-country .multiselect__tags {
  padding: 0px 40px 0px 12px !important;
}

.multiselect--above .multiselect__content-wrapper {
  top: 100% !important;
  bottom: auto !important;
}

.multiselect-form-country .multiselect__select {
  -webkit-transition: none !important;
  transition: none !important;
}

.multiselect-form-timezone .multiselect__select {
  -webkit-transition: none !important;
  transition: none !important;
  color: black;
}

.multiselect__option--highlight {
  background: #000000;
  outline: none;
  color: #fff;
}

.details-select-profile {
  color: black;
}

.multiselect--active .multiselect__select {
  -webkit-transform: none;
  transform: none;
}

.multiselect__select::before {
  top: 35%;
}

.multiselect__single {
  padding: 0px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  line-height: 30px;
  font-size: 16px;
  letter-spacing: 0.01em;
}
.multiselect__placeholder {
  padding-top: 0px !important;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 30px;
  font-size: 16px;
  letter-spacing: 0.01em;
}
.multiselect__tags {
  padding: 0px 40px 0px 13px !important;
}
.multiselect__input {
  padding: 0px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 30px;
}

.multiselect__select {
  height: 52px;
  width: 50px;
  margin-top: 0px !important;
  padding: 0px !important;
}

.form-control {
  height: 52px;
}

.md-button,
.md-button-clean {
  transition: none !important;
}

.multiselect__option--selected {
  background: #b7227e !important;
  color: #ffffff !important;
  font-weight: 500 !important;
}

.multiselect__option--selected span {
  color: #fff !important;
}

.vue-tel-input.tel-admin-input {
  width: 100%;
  height: 52px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.tel-admin-input.vti__input {
  font-weight: 400;
}

.tel-admin-input.vti__input::placeholder {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
}

.tel-admin-input.vue-tel-input:focus-within {
  outline: none;
  border-color: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: none !important;
  border-top-color: none !important;
  border-right-color: none !important;
  border-bottom-color: none !important;
  border-left-color: none !important;
  border: 1px solid #ddd;
}

.tel-admin-input .vti__dropdown-item strong {
  font-weight: 400;
}
</style>
