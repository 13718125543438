<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <card class="border-bottom-cards tab-admin-config">
          <md-tabs
            :md-active-tab="currentTab"
            class="style-config admin"
            @md-changed="handleTab"
          >
            <md-tab id="cuenta" md-label="MI CUENTA">
              <div class="mr-1 ml-1">
                <h6 class="heading-small text-color-title mb-3">PERFIL</h6>
                <setting-account />
              </div>
            </md-tab>
            <md-tab id="reportes" md-label="REPORTES">
              <div class="mr-1 ml-1">
                <h6 class="heading-small text-color-title mb-3">REPORTES</h6>
                <setting-notification />
              </div>
            </md-tab>
          </md-tabs>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import SettingAccount from './components/SettingAccount';
import SettingNotification from './components/SettingNotification';
export default {
  name: 'SettingsView',
  components: {
    SettingAccount,
    SettingNotification,
  },
  data() {
    return {
      currentTab: '',
    };
  },
  created() {
    if (this.$route.params.option) {
      this.currentTab = this.$route.params.option;
    }
  },
  mounted() {
    console.log('test');
  },
  methods: {
    handleTab(tabName) {
      if (tabName !== this.currentTab && this.$route.path) {
        this.currentTab = tabName;
        this.$router.push(`/admin/configuracion/${tabName}`);
      }
    },
  },
};
</script>

<style>
.tab-admin-config .md-tabs.md-theme-default .md-tabs-navigation {
  border: 1px solid #ddd;
  border-radius: 10px;
}
.tab-admin-config .md-tabs.md-alignment-left .md-tabs-navigation {
  justify-content: flex-start;
  width: fit-content;
}
.tab-admin-config
  .md-tabs.md-theme-default
  .md-tabs-navigation
  .md-button.md-active {
  background: #b7227e;
  color: #fff !important;
  border-radius: 10px;
}
.tab-admin-config .md-active .md-button-content {
  color: #fff !important;
}
.tab-admin-config .style-config .md-tabs-indicator,
.tab-admin-config .style-config .md-tabs-indicator.md-tabs-indicator-left,
.tab-admin-config .style-config .md-tabs-indicator.md-tabs-indicator-right {
  background-color: transparent !important;
}

.tab-admin-config .md-tabs.md-alignment-left .md-tabs-navigation {
  padding: 5px;
}
.card-body {
  padding: 0.2rem;
}

.multiselect__option--selected {
  background: #b7227e !important;
  color: #ffffff !important;
  font-weight: 500 !important;
}

.md-tabs-navigation .md-ripple {
  z-index: 1;
}
</style>
