<template>
  <md-dialog :md-active="open" class="modal-close">
    <span
class="material-icons close text-right"
@click="closeModal"
      >close</span
    >
    <div class="body-modal-course">
      <md-dialog-title>ACTUALIZAR FOTO DE PERFIL</md-dialog-title>
      <md-dialog-content>
        <div v-if="!cropperVisible" class="dropzone-profile-instructor mt-3">
          <md-field class="dropzone-img">
            <span
              v-if="!formData.url"
              class="material-icons add-photo profile-icon"
              >add_a_photo</span
            >
            <md-file
              :style="{
                backgroundImage: `url('${formData.url}')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '95% 95%',
                borderRadius: '0.25rem',
                backgroundPosition: 'center',
              }"
              accept="image/*"
              class="dropzone-img-profile"
              @md-change="changeFile($event)"
            />
          </md-field>
        </div>
      </md-dialog-content>
      <md-dialog-content>
        <vue-cropper
          v-if="cropperVisible"
          ref="cropper"
          :aspect-ratio="1"
          :src="formData.url"
          preview=".preview"
        />
      </md-dialog-content>

      <md-dialog-actions>
        <base-button
          class="btn base-button m-0 cancel-button-outline"
          style="width: 100%"
          @click="closeModal"
          >CANCELAR
        </base-button>

        <base-button
          :loading="isLoading"
          class="btn base-button md-primary mb-1 mb-2"
          @click="updateUserAvatar"
          >ACTUALIZAR
        </base-button>
      </md-dialog-actions>
    </div>
  </md-dialog>
</template>

<script>
import BaseButton from '../../../../../components/BaseButton';
import { UPDATE_USER_AVATAR } from '../../../../../store/actions/user';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  name: 'UpdateUserAvatarDialog',
  components: {
    BaseButton,
    VueCropper,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: undefined,
    },
  },
  emits: ['close-modal', 'success'],
  data() {
    return {
      isLoading: false,
      formData: {
        id: '',
        url: '',
      },
      avatar: undefined,
      cropImg: '',
      cropperVisible: false,
    };
  },
  computed: {
    open: {
      get: function () {
        return this.isOpen;
      },
      set: function () {
        this.closeModal();
      },
    },
  },
  watch: {
    data: function (newValue) {
      if (newValue) {
        this.formData = {
          url: newValue.url,
          id: newValue.id,
        };
      } else this.reset();
    },
  },
  methods: {
    reset() {
      this.formData = {
        id: '',
        url: '',
      };
      this.avatar = undefined;
      this.cropperVisible = false;
    },
    closeModal() {
      this.reset();
      this.$emit('close-modal');
    },

    changeFile(event) {
      this.formData.url = URL.createObjectURL(event[0]);
      this.avatar = event[0];
      this.cropperVisible = true;
      if (!this.isFileAnImage(this.avatar)) {
        alert('Please select an image file');
        return;
      }
      this.readFileData(this.avatar);
    },

    isFileAnImage(file) {
      return file.type.indexOf('image/') !== -1;
    },

    readFileData(file) {
      if (typeof FileReader === 'function') {
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
          this.$refs.cropper.replace(event.target.result);
        };
        fileReader.readAsDataURL(file);
      }
    },

    updateUserAvatar() {
      this.avatar = this.$refs.cropper
        .getCroppedCanvas()
        .toDataURL('image/png');

      let datafile = atob(this.avatar.split(',')[1]); // Decodificar los datos base64
      let array = []; // Crear un array vacío
      for (let i = 0; i < datafile.length; i++) {
        array.push(datafile.charCodeAt(i)); // Añadir cada byte al array
      }
      let blob = new Blob([new Uint8Array(array)], { type: 'image/png' }); // Crear un blob con el tipo adecuado
      this.avatar = new File([blob], 'avatar.png', { type: 'image/png' }); // Crear un archivo con el nombre y el tipo adecuados

      const data = new FormData();
      if (this.avatar) {
        data.append('avatar', this.avatar);
      }
      data.append('file_id', this.formData.id);
      this.isLoading = true;
      this.$store
        .dispatch(UPDATE_USER_AVATAR, data)
        .then(() => {
          this.$emit('success');
          this.isLoading = false;
          this.closeModal();
          this.notify(
            'success',
            'Se actualizo su foto de perfil correctamente',
          );
        })
        .catch(() => {
          this.isLoading = false;
          this.notify(
            'danger',
            'Tenemos problemas para actualizar su foto de perfil',
          );
        });
    },

    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
  },
};
</script>

<style>
.dropzone-img-profile {
  max-width: 300px !important;
  max-height: 300px !important;
  height: 300px !important;
}

.dropzone-img-profile .md-input {
  max-width: 300px !important;
  height: 300px !important;
}
.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
  height: 100%;
  width: auto;
  align-content: center;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px rgb(192, 0, 90);
  outline: 0;
}

.cropper-bg {
  max-height: 300px !important;
  width: auto !important;
}
.cropper-point {
  background-color: #b7227e;
}

.md-dialog-actions button:nth-child(1):hover {
  color: black !important;
  background-color: white !important;
}
</style>
