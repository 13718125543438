<template>
  <div class="row" style="height: 500px">
    <div class="col-sm-12 mb-4">
      <div class="y-4">
        <div
          v-if="!getUserRoles.includes('admin')"
          class="d-flex align-items-center"
        >
          <toggle-button
            :color="colors"
            :sync="true"
            :value="!!notification.payment"
            name="payment"
            @change="onChange($event)"
          />
          <p class="p-0 m-0 ml-3 style-text-config">
            Recibir una alerta cuando procesen mi pago.
          </p>
        </div>
        <div
          v-if="!getUserRoles.includes('admin')"
          class="mt-2 d-flex align-items-center"
        >
          <toggle-button
            :color="colors"
            :sync="true"
            :value="!!notification.active"
            name="active"
            @change="onChange($event)"
          />
          <p class="p-0 m-0 ml-3 style-text-config">
            Recibir alerta de contenido asignado en Activos.
          </p>
        </div>
        <div>
          <p class="p-0 m-0 pb-4">
            Recibir resumen de estadísticas de la plataforma enviado a su
            dirección de correo electrónico.
          </p>
        </div>
        <div class="mt-2 d-flex align-items-center">
          <toggle-button
            :color="colors"
            :sync="true"
            :value="!!notification.report"
            name="report"
            @change="onChange($event)"
          />
          <p class="p-0 m-0 ml-3 style-text-config">{{ statusText }}</p>
        </div>
        <div class="mt-4 d-flex align-items-center time-report">
          <multiselect
            v-model="notification.time"
            :options="options"
            :show-labels="false"
            class="w-50 select-reports-user"
            label="name"
            placeholder="Seleccione"
          />
          <p class="pl-2 py-0 pr-0 m-0 style-text-config">
            Tiempo envío de reporte.
          </p>
        </div>
        <div class="mt-4">
          <base-button
            :loading="isLoading"
            class="btn btn-maquillate py-2 px-3"
            @click="updateNotification"
            >ACTUALIZAR
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import {
  UPDATE_USER_NOTIFICATION,
  USER_REQUEST,
} from '../../../../../store/actions/user';
import BaseButton from '../../../../../components/BaseButton';

export default {
  name: 'SettingNotification',
  components: {
    Multiselect,
    BaseButton,
  },
  data() {
    return {
      isLoading: false,
      colors: {
        checked: '#b7227e',
        unchecked: '#fbd6ed',
      },
      notification: {
        payment: false,
        active: false,
        report: false,
        time: '',
      },
      options: [
        { name: 'Diario', value: 'DAILY' },
        { name: 'Semanal', value: 'WEEKLY' },
        { name: 'Quincenal', value: 'BIWEEKLY' },
        { name: 'Mensual', value: 'MONTHLY' },
      ],
    };
  },
  computed: {
    ...mapGetters(['getProfile', 'getUserRoles']),
    statusText() {
      return this.notification.report
        ? 'El resumen de estadísticas ha sido activado'
        : 'El resumen de estadísticas ha sido desactivado';
    },
  },
  watch: {
    getProfile: function (newValue) {
      const time = this.options.find(
        (option) => option.value === newValue.notification.time,
      );
      this.notification = {
        payment: newValue.notification.payment,
        active: newValue.notification.active,
        report: newValue.notification.report,
        time,
      };
    },
  },
  mounted() {
    const time = this.options.find(
      (option) => option.value === this.getProfile.notification.time,
    );

    this.notification = {
      payment: this.getProfile.notification.payment,
      active: this.getProfile.notification.active,
      report: this.getProfile.notification.report,
      time,
    };
  },

  methods: {
    onChange({ srcEvent, value }) {
      console.log('event', srcEvent.target.name);
      this.notification = {
        ...this.notification,
        [srcEvent.target.name]: value,
      };
    },

    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    fetchUser() {
      this.$store.dispatch(USER_REQUEST);
    },
    updateNotification() {
      this.isLoading = true;
      this.$store
        .dispatch(UPDATE_USER_NOTIFICATION, {
          time: this.notification.time.value,
          payment: this.notification.payment ? 1 : 0,
          active: this.notification.active ? 1 : 0,
          report: this.notification.report ? 1 : 0,
        })
        .then(() => {
          this.isLoading = false;
          this.fetchUser();
          this.notify(
            'success',
            'Se actualizo las notificaciones correctamente',
          );
        })
        .catch(() => {
          this.isLoading = false;
          this.notify(
            'danger',
            'Tenemos problemas para actualizar las notificaciones',
          );
        });
    },
  },
};
</script>

<style>
.select-reports-user .multiselect__select {
  position: absolute;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  -webkit-transition: none !important;
  transition: none !important;
  margin-top: 0px;
}

.select-reports-user .multiselect__option--highlight {
  background: #000000 !important;
  outline: none;
  color: #fff !important;
}

.select-reports-user .multiselect__option--selected {
  background: #f3f3f3;
  color: #000000;
  font-weight: 700;
}

.multiselect__select::before {
  top: 35%;
}

.select-reports-user .multiselect__select {
  height: 50px;
  width: 50px;
  margin-top: 0px !important;
  padding: 0px !important;
}
</style>
