var render = function render(){var _vm=this,_c=_vm._self._c;return _c('md-dialog',{staticClass:"modal-close",attrs:{"md-active":_vm.open}},[_c('span',{staticClass:"material-icons close text-right",on:{"click":_vm.closeModal}},[_vm._v("close")]),_c('div',{staticClass:"body-modal-course"},[_c('md-dialog-title',[_vm._v("ACTUALIZAR FOTO DE PERFIL")]),_c('md-dialog-content',[(!_vm.cropperVisible)?_c('div',{staticClass:"dropzone-profile-instructor mt-3"},[_c('md-field',{staticClass:"dropzone-img"},[(!_vm.formData.url)?_c('span',{staticClass:"material-icons add-photo profile-icon"},[_vm._v("add_a_photo")]):_vm._e(),_c('md-file',{staticClass:"dropzone-img-profile",style:({
                backgroundImage: `url('${_vm.formData.url}')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '95% 95%',
                borderRadius: '0.25rem',
                backgroundPosition: 'center',
              }),attrs:{"accept":"image/*"},on:{"md-change":function($event){return _vm.changeFile($event)}}})],1)],1):_vm._e()]),_c('md-dialog-content',[(_vm.cropperVisible)?_c('vue-cropper',{ref:"cropper",attrs:{"aspect-ratio":1,"src":_vm.formData.url,"preview":".preview"}}):_vm._e()],1),_c('md-dialog-actions',[_c('base-button',{staticClass:"btn base-button m-0 cancel-button-outline",staticStyle:{"width":"100%"},on:{"click":_vm.closeModal}},[_vm._v("CANCELAR ")]),_c('base-button',{staticClass:"btn base-button md-primary mb-1 mb-2",attrs:{"loading":_vm.isLoading},on:{"click":_vm.updateUserAvatar}},[_vm._v("ACTUALIZAR ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }